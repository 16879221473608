var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import api from '../../../app/api-generated/mapGeoData';
const NOT_FOUND_ERROR = 'No match found';
export default function getBoundingBox(query_1) {
    return __awaiter(this, arguments, void 0, function* (query, format = 'json') {
        /* Returns the bounding box from a request from NOMINATIM via GeoAPI */
        let theBBox;
        const model = {
            query: query,
            country: null,
            includeAddress: null,
            forceLocal: null,
        };
        const response = yield api.geoCodeFind(model);
        const response_json = yield response.value;
        // No coordinates found
        if (!response_json['data'] || response_json['data'].length == 0) {
            throw NOT_FOUND_ERROR;
        }
        // Coordinates found; take first object and extract the boundingbox
        const llarray = response_json['data'][0]['boundingbox'];
        theBBox = {
            min_lat: llarray[0],
            max_lat: llarray[1],
            min_lng: llarray[2],
            max_lng: llarray[3],
        };
        return theBBox;
    });
}
export { NOT_FOUND_ERROR };
