import { SvgRepository } from '@dnx/core'

const repository = new SvgRepository({baseUrl: '/assets/map-icons/'})

/** @type {string} */
let markerSvg = repository.get('marker', download => markerSvg = download?.outerHTML ?? '<svg/>' )

/**
 * Create an array of variants based on the given name, variants are based on pluralization/removal of prefixes
 * this to combat inconsistencies in naming used by our icons <> our system
 *
 * shops_supermarket => shops_supermarket, shops_supermarkets, supermarket, supermarkets
 *
 * @param {string} name
 * @returns {string[]}
 */
function* getVariants(name) {
  for (; name; name = name.indexOf('_') > 0 ? name.slice( name.indexOf('_') ) : undefined ) {
    yield name;
    // And the plural/singular version
    yield name.endsWith('s') ? name.substring(0, name.length - 1) : name + 's';
  }
}

/** Custom icons */
const customicon = {
  'zeiss': 'logo',
  'branche': 'logo',
  shops_department_store: 'department_store',
  shops_all: 'shop',
}

/** Loaded map-icons */
const iconmap = {}

/** Clean name: './Car.png' => 'car' */
const clean = (value) => (value||'').toLowerCase().replace(/\.(\/|\\|png)/gmi, '')

function getSvgIconImpl(name) {
  name = clean(name);
  name = customicon[name] || name;

  if (!name) return '';

  for (const variant of getVariants(name)) {
    if (iconmap[variant]) return iconmap[variant];

    //TODO: Initial load will use placeholder until rerendered
    const icon = repository.get(variant, download => download && ( iconmap[variant] = download.outerHTML ) )

    const svg = icon?.outerHTML
    if (svg) return svg
  }

  return '';
}

function buildSvgMarkerWithIcon(iconName) {
  //Ignore markers
  if (iconName === 'marker') iconName = '';

  //Always start off with a blank marker
  let iconSvg = markerSvg.replace('<svg', `<svg class="maps-svg-icon"`);

  //Add our actual icon on top of it by assigning it an absolute position
  iconSvg += getSvgIconImpl(iconName).replace(
      '<svg',
      `<svg class="maps-svg-icon" style="position: absolute; top: 0; left: 0;"`
  );

  return iconSvg;
}

function getSvgIcon(iconName) {
  return getSvgIconImpl(iconName).replace('<svg', `<svg class="maps-svg-icon"`);
}

export { buildSvgMarkerWithIcon, getSvgIcon };
