export var AmenityTypes;
(function (AmenityTypes) {
    AmenityTypes["primary_education"] = "primary_education";
    AmenityTypes["secondary_education"] = "secondary_education";
    AmenityTypes["bar_pub_cafe"] = "bar_pub_cafe";
    AmenityTypes["restaurant"] = "restaurant";
    AmenityTypes["shops_all"] = "shops_all";
    AmenityTypes["shops_department_store"] = "shops_department_store";
    AmenityTypes["shops_supermarket"] = "shops_supermarket";
    AmenityTypes["shops_mall"] = "shops_mall";
    AmenityTypes["shops_wholesale"] = "shops_wholesale";
    AmenityTypes["medical"] = "medical";
    AmenityTypes["dentist"] = "dentist";
    AmenityTypes["pharmacy"] = "pharmacy";
    AmenityTypes["emergency_services"] = "emergency_services";
    AmenityTypes["hotels"] = "hotels";
    AmenityTypes["campings"] = "camping";
})(AmenityTypes || (AmenityTypes = {}));
