var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { requestHandler as handler } from '../api/helpers';
import QueryStringBuilder from '../utils/querystringbuilder';
import { jsonRequestBodyWriter } from '../utils/apirequestbodywriter';
class Map {
    constructor() {
        this.paths = Object.freeze({
            get: (id) => {
                const __query = new QueryStringBuilder().add("id", id);
                return `api/maps/map${__query.toString()}`;
            },
            list: () => `api/maps/map/list`,
            post: () => `api/maps/map`,
            put: () => `api/maps/map`,
            customers: (divisionid, north, south, west, east) => {
                const __query = new QueryStringBuilder().add("DivisionId", divisionid).add("North", north).add("South", south).add("West", west).add("East", east);
                return `api/maps/map/customers${__query.toString()}`;
            },
            orders: (divisionid, north, south, west, east) => {
                const __query = new QueryStringBuilder().add("DivisionId", divisionid).add("North", north).add("South", south).add("West", west).add("East", east);
                return `api/maps/map/orders${__query.toString()}`;
            },
            branches: (divisionid, north, south, west, east) => {
                const __query = new QueryStringBuilder().add("DivisionId", divisionid).add("North", north).add("South", south).add("West", west).add("East", east);
                return `api/maps/map/branches${__query.toString()}`;
            },
            delete: (id) => {
                const __query = new QueryStringBuilder().add("id", id);
                return `api/maps/map${__query.toString()}`;
            },
        });
    }
    /**
     * Map.Get "GET api/maps/map"
     *
     * Swagger docs
     * @param {number} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<MapResponseModel>>}
     */
    get(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("id", id);
            let path = `api/maps/map${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Map.List "GET api/maps/map/list"
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<MapModel[]>>}
     */
    list(options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/maps/map/list`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Map.Post "POST api/maps/map"
     * @param {MapCreateRequestModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<MapModel>>}
     */
    post(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/maps/map`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("POST");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Map.Put "PUT api/maps/map"
     * @param {MapUpdateRequestModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<MapModel>>}
     */
    put(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            let path = `api/maps/map`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("PUT");
            jsonRequestBodyWriter.write(request, model);
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Map.Customers "GET api/maps/map/customers"
     * @param {GetGeoFeatureCollectionRequestModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<GeoFeatureCollectionModel>>}
     */
    customers(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("DivisionId", model.divisionId).add("North", model.north).add("South", model.south).add("West", model.west).add("East", model.east);
            let path = `api/maps/map/customers${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Map.Orders "GET api/maps/map/orders"
     * @param {GetGeoFeatureCollectionRequestModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<GeoFeatureCollectionModel>>}
     */
    orders(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("DivisionId", model.divisionId).add("North", model.north).add("South", model.south).add("West", model.west).add("East", model.east);
            let path = `api/maps/map/orders${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Map.Branches "GET api/maps/map/branches"
     * @param {GetGeoFeatureCollectionRequestModel} model
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<GeoFeatureCollectionModel>>}
     */
    branches(model, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("DivisionId", model.divisionId).add("North", model.north).add("South", model.south).add("West", model.west).add("East", model.east);
            let path = `api/maps/map/branches${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("GET");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
    /**
     * Map.Delete "DELETE api/maps/map"
     * @param {number} id
     * @param {*} options Object for providing extra parameters for the performed API call
     * @param {AbortSignal} options.signal Signal used for triggering cancellation of pending or running request
     * @returns {Promise<DnxResponse<unknown>>}
     */
    delete(id, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const __query = new QueryStringBuilder().add("id", id);
            let path = `api/maps/map${__query.toString()}`;
            const listQuery = new QueryStringBuilder();
            if (options === null || options === void 0 ? void 0 : options.queryParameters) {
                Object.entries(options === null || options === void 0 ? void 0 : options.queryParameters).forEach((entry) => {
                    listQuery.add(entry[0], entry[1]);
                });
                path += listQuery.toString();
            }
            const request = yield handler.getAuthorizedRequestOptions("DELETE");
            const response = yield handler.fetch(path, Object.assign(Object.assign({}, request), { signal: options === null || options === void 0 ? void 0 : options.signal }));
            return handler.handleResponse(response);
        });
    }
}
const map = new Map();
export { map as default, Map };
