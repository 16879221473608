import { LatLng } from 'leaflet';
import { FeatureType } from '.';
import { ToolType } from './tooltype';
import { Transportation } from './transportation';
import { AmenityTypes } from './amenitytypes';
import { CustomerDataTypes } from './CustomerDataTypes';
import { AreaTypes } from './AreaTypes';
const createLayer = (layerId) => ({
    id: layerId,
    leafletId: 0,
    featureType: null,
    group: null,
    isDraggable: true,
    isDragging: false,
    isVisible: true,
    isHighlighted: false,
    geoJSON: null,
});
export const createMarkerLayer = (layerId, options = {}) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, createLayer(layerId)), { featureType: FeatureType.LMarker, options: {
            type: ToolType.Marker,
            name: (_a = options.name) !== null && _a !== void 0 ? _a : '',
            description: (_b = options.description) !== null && _b !== void 0 ? _b : '',
            position: options && options.position
                ? new LatLng(options.position.lat, options.position.lng)
                : new LatLng(0, 0),
            fillColor: options.fillColor || '#2783c6',
            iconPath: options.iconPath || null,
            iconSize: options.iconSize || 'small',
        } }));
};
export const createCircleLayer = (layerId, options = {}) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, createLayer(layerId)), { featureType: FeatureType.LCircle, options: {
            type: ToolType.Circle,
            name: (_a = options.name) !== null && _a !== void 0 ? _a : '',
            description: (_b = options.description) !== null && _b !== void 0 ? _b : '',
            position: options && options.position
                ? new LatLng(options.position.lat, options.position.lng)
                : new LatLng(0, 0),
            radius: options.radius || 500,
            fillColor: options.fillColor || '#aabbcc',
            iconPath: options.iconPath || null,
            iconSize: options.iconSize || 'small',
        } }));
};
export const createIsoLayer = (layerId, options = {}) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, createLayer(layerId)), { featureType: FeatureType.LGeojson, options: Object.assign({ type: ToolType.Isochrone, name: (_a = options.name) !== null && _a !== void 0 ? _a : '', description: (_b = options.description) !== null && _b !== void 0 ? _b : '', transport: options.transport || Transportation.cycling, minutes: options.minutes || [5] }, options) }));
};
export const createAmenityLayer = (layerId, options = {}) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, createLayer(layerId)), { featureType: FeatureType.LGeojson, options: Object.assign({ type: ToolType.Amenity, amenityType: AmenityTypes.primary_education, name: (_a = options.name) !== null && _a !== void 0 ? _a : '', description: (_b = options.description) !== null && _b !== void 0 ? _b : '', featurePopup: '' }, options) }));
};
export const createAreasLayer = (layerId) => (Object.assign(Object.assign({}, createLayer(layerId)), { featureType: FeatureType.LGeojson, options: {
        type: ToolType.Areas,
        name: '',
        description: '',
        geb_year: null,
        geb_type: AreaTypes.gemeente,
        geb_code_array: null,
        pv_code_array: null,
        featurePopup: '',
    } }));
export const createCustomerDataLayer = (layerId, options = {}) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, createLayer(layerId)), { featureType: FeatureType.LGeojson, options: Object.assign({ type: ToolType.CustomerData, customerDataType: CustomerDataTypes.customers, name: (_a = options.name) !== null && _a !== void 0 ? _a : '', description: (_b = options.description) !== null && _b !== void 0 ? _b : '', featurePopup: '' }, options) }));
};
