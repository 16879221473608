var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import mapsApi from '../../../app/api-generated/map';
import geoApi from '../../../app/api-generated/mapGeoData';
import * as L from 'leaflet/dist/leaflet-src.esm';
import { createResetMutation } from '@dnx/core';
const getRandomName = () => 'randomname';
import { FeatureType, LayerGroupType } from './models';
import { createAmenityLayer, createAreasLayer, createCircleLayer, createCustomerDataLayer, createIsoLayer, createMarkerLayer, } from './models/layerFactory';
import ZoomService from './zoomservice';
import { Transportation } from './models/transportation';
import { ToolType } from './models/tooltype';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import SchedulerService from './schedulerservice';
const GET_INITIAL_STATE = () => ({
    selectedMapBySearch: null,
    allStatistics: null,
    measureFilterOptions: null,
    maxBounds: new L.LatLngBounds(), //[new LatLng(-90, -180), new LatLng(90, 180)],
    maps: [],
    unresolvedRequestCount: 0,
    map: {
        id: -1,
        name: '',
        description: '',
        zoom: 14,
        center: new L.LatLng(53.225698, 6.556869),
    },
    selectedLayer: undefined,
    layerGroups: [],
    tileProviders: [
        {
            id: 0,
            name: 'OpenStreetMap',
            visible: true,
            attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
            id: 1,
            name: 'Satellite',
            visible: false,
            url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        },
        // {
        //     id: 2,
        //     name: 'OpenTopoMap',
        //     visible: false,
        //     url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
        //     attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        // },
    ],
    selectedMap: {
        mapOptions: {
            zoomControl: false,
            attributionControl: false,
        },
        maxZoom: 19,
        minZoom: 8, //Limited to 8 to avoid data loading issues, can increase after implementing a better tiling strategy
    },
});
let nextLayerId = 1;
let nextGroupId = 1;
let mapRef = null;
let zoomService = null;
const scheduler = new SchedulerService();
export default {
    namespaced: true,
    state: GET_INITIAL_STATE(),
    getters: {
        getMaps(state) {
            return state.maps;
        },
        getZoom: (state) => { var _a; return (_a = zoomService === null || zoomService === void 0 ? void 0 : zoomService.currentZoom()) !== null && _a !== void 0 ? _a : state.map.zoom; },
        getCenter: (state) => state.map.center,
        getMapBounds: (state) => mapRef.getBounds(),
        getSelectedMapBySearch: (state) => state.selectedMapBySearch,
        getAllStatistics: (state) => state.allStatistics,
        getMeasureFilterOptions: (state) => state.measureFilterOptions,
        getMapName: (state) => state.map.name,
        getMapDescription: (state) => state.map.description,
        getLayerGroups: (state) => state.layerGroups,
        getMarkerLayers(state) {
            return state.layerGroups.flatMap(x => x.layers).filter(x => x.featureType === FeatureType.LMarker);
        },
        getCircleLayers(state) {
            return state.layerGroups.flatMap(x => x.layers).filter(x => x.featureType === FeatureType.LCircle);
        },
        getGeoJsonLayers(state) {
            return state.layerGroups.flatMap(x => x.layers).filter(x => x.featureType === FeatureType.LGeojson);
        },
        isLoading: (state) => state.unresolvedRequestCount > 0,
        getSelectedLayer: (state) => state.selectedLayer,
    },
    actions: {
        fetchMap(_a, payload_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit, state }, payload) {
                if (payload.mapId === state.map.id) {
                    // map has already been set, don't even get from api.
                    return;
                }
                commit('setLoadingState', { isLoading: true });
                const map = yield mapsApi.get(payload.mapId);
                commit('setLoadingState', { isLoading: false });
                if (payload.mapId === state.map.id) {
                    // map has been set during api call, don't overwrite and lose leaflet ids.
                    return;
                }
                commit('setMap', map.value);
            });
        },
        fetchMaps(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                commit('setLoadingState', { isLoading: true });
                const maps = yield mapsApi.list();
                commit('setLoadingState', { isLoading: false });
                commit('setMaps', maps.value);
            });
        },
        fetchIsochroneGeoJson(_a, payload_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit, state }, payload) {
                var _b, _c;
                const group = state.layerGroups.find(x => x.groupId === payload.groupId);
                if (group) {
                    const position = group.layers[0].options.position;
                    const transport = (_b = group.layers[1].options.transport) !== null && _b !== void 0 ? _b : Transportation.walking;
                    const distance = (_c = group.layers[1].options.minutes) !== null && _c !== void 0 ? _c : [5];
                    commit('setLoadingState', { isLoading: true });
                    const response = yield geoApi.postIsochrones({
                        latitude: position.lat,
                        longitude: position.lng,
                        profile: transport,
                        minutes: distance,
                    });
                    commit('setLoadingState', { isLoading: false });
                    if (response.success) {
                        commit('setGeoJson', {
                            groupId: payload.groupId,
                            geoJson: response.value.data,
                        });
                    }
                }
            });
        },
        fetchAreasGeoJson(_a, payload_1) {
            return __awaiter(this, arguments, void 0, function* ({ dispatch, commit, state }, payload) {
                /* In BWG REQUESTMODEL : ? on wrong params? */
                let theType;
                switch (payload.geb_type) {
                    case 'buurt':
                        theType = 0;
                        break;
                    case 'wijk':
                        theType = 1;
                        break;
                    case 'gemeente':
                        theType = 2;
                        break;
                }
                const layer = state.layerGroups
                    .find((lg) => lg.groupId === payload.layer.group.groupId)
                    .layers.find((ly) => ly.id === payload.layer.id);
                if (layer) {
                    // Get data
                    commit('setLoadingState', { isLoading: true });
                    const response = yield geoApi.postBwg({
                        year: 2020,
                        type: 'wijk',
                        municipalityCodes: ['GM0518'],
                        provinceCodes: ['pv28'],
                    });
                    commit('setLoadingState', { isLoading: false });
                    // Set geojson to layer
                    if (response.success) {
                        yield commit('setGeoJson', {
                            layerId: payload.layer.id,
                            geoJson: response.value.data,
                        });
                    }
                }
            });
        },
        saveMap(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit, state }) {
                const map = {
                    projectId: 1,
                    id: state.map.id,
                    name: state.map.name.trim().length > 0 ? state.map.name : getRandomName(),
                    description: state.map.description,
                    center: { lat: state.map.center.lat, lng: state.map.center.lng },
                    zoom: state.map.zoom,
                };
                if (map.id <= 0) {
                    // new map, needs to be created first before we can send the layers
                    commit('setLoadingState', { isLoading: true });
                    const result = yield mapsApi.post(map);
                    commit('setLoadingState', { isLoading: false });
                    map.id = result.value.id;
                    commit('setMapOption', { id: result.value.id });
                }
                const layerGroups = state.layerGroups.map(group => {
                    return {
                        groupId: group.groupId,
                        type: group.type,
                        layers: group.layers.map(layer => ({
                            options: Object.assign(Object.assign({}, layer.options), layer.styling),
                            geoJSON: layer.geoJSON,
                            type: layer.type,
                        })),
                    };
                });
                const payload = {
                    map: map,
                    layerGroups: layerGroups,
                };
                commit('setLoadingState', { isLoading: true });
                const response = (yield mapsApi.put(payload));
                commit('setLoadingState', { isLoading: false });
                if (response.success) {
                    commit('setMap', response.value);
                }
                commit('setDirty', false);
                return {
                    success: response.success,
                    message: response.message,
                };
            });
        },
        layerCreated(_a, payload_1) {
            return __awaiter(this, arguments, void 0, function* ({ dispatch, commit, state, getters }, payload) {
                // 1. Register leaflet Id
                yield commit('setLayerOptions', {
                    id: payload.layer.id,
                    leafletId: payload.leafletId,
                });
                // 2. Optionally zoom
                yield dispatch('optionalZoom', { group: payload.layer.group });
            });
        },
        deleteMap(_a, id_1) {
            return __awaiter(this, arguments, void 0, function* ({ dispatch }, id) {
                const response = yield mapsApi.delete(id);
                if (response.success)
                    dispatch('fetchMaps');
                return response;
            });
        },
        fetchAllStatistics(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const response = yield geoApi.getMeasurements();
                commit('setAllStatistics', response.value.data);
            });
        },
        fetchMeasureFilterOptions(_a, id_1) {
            return __awaiter(this, arguments, void 0, function* ({ commit }, id) {
                const response = yield geoApi.getMeasureFilterOptions(id);
                commit('setMeasureFilterOptions', response.value.data);
            });
        },
        // Zoom fucntionalities
        flyToAllLayers({ state }) {
            /* Flies to bounds that include all layers on the map */
            const leafletIdArray = state.layerGroups.flatMap(lg => lg.layers).map(layer => layer.leafletId);
            zoomService.zoomToLayers(leafletIdArray);
        },
        flyToGroup({ state }, group) {
            zoomService.zoomToLayers(group.layers.map((ly) => ly.leafletId));
        },
        flyToLayers({ state }, leafletIdArray) {
            /* Zooms to layer that correspond to a leafletId in the proviced leafletIdArray*/
            zoomService.zoomToLayers(leafletIdArray);
        },
        flyToToolType({ state }, layerGroupType) {
            /* Zooms to all layers of a specific layergrouptype */
            const leafletIdArray = state.layerGroups
                .filter(lg => lg.type == layerGroupType)
                .flatMap(lg => lg.layers)
                .map(layer => layer.leafletId);
            zoomService.zoomToLayers(leafletIdArray);
        },
        flyToBoundingBox({ state }, bounds) {
            /* Zooming to a bbbox */
            zoomService.zoomToBbox(bounds);
        },
        optionalZoom({ getters }, payload) {
            /* Checks if the provided layers' latlngbounds exceed the current view of the map. If so: zooms to layer. */
            setTimeout(() => {
                const layerBounds = zoomService.getBoundsLayers(payload.group.layers.map((ly) => ly.leafletId));
                if (!layerBounds)
                    return; //Bounds unavailable, layer empty?
                const mapBounds = getters.getMapBounds;
                // Layer > mapView --> zoom out
                if (layerBounds.contains(mapBounds))
                    zoomService.zoomToBbox(layerBounds);
                // Layer is outside of mapView --> zoom to
                if (!mapBounds.contains(layerBounds.getCenter()))
                    zoomService.zoomToBbox(layerBounds);
            }, 0);
        },
        // / Zoomfunctionalities
    },
    mutations: {
        resetMapConfig: function (...args) {
            scheduler.reset();
            createResetMutation(GET_INITIAL_STATE).apply(this, args);
        },
        resetMap(state) {
            const initialState = GET_INITIAL_STATE();
            state.previewMapId = initialState.previewMapId;
            state.map = initialState.map;
            state.layerGroups = initialState.layerGroups;
        },
        setLoadingState(state, payload) {
            if (payload.isLoading)
                state.unresolvedRequestCount++;
            else
                state.unresolvedRequestCount--;
        },
        setSelectedMap: (state, payload) => (state.selectedMapBySearch = payload),
        setSelectedLayer: (state, payload) => (state.selectedLayer = payload),
        setMaps(state, payload) {
            state.maps = payload;
        },
        setDirty(state, payload) {
            state.dirty = payload;
        },
        setMap(state, payload) {
            nextGroupId = 1;
            nextLayerId = 1;
            state.map = Object.assign({}, payload.map);
            state.map.center = new L.LatLng(state.map.center.lat, state.map.center.lng);
            // Recreate id's to make sure we can just keep adding layers
            // after without collisions
            state.layerGroups = payload.layerGroups.map((group) => {
                const result = Object.assign(Object.assign({}, group), { groupId: nextGroupId++, layers: group.layers.map(layer => {
                        switch (layer.options.type) {
                            case ToolType.Marker:
                                return createMarkerLayer(nextLayerId++, layer.options);
                            case ToolType.Circle:
                                return createCircleLayer(nextLayerId++, layer.options);
                            case ToolType.Isochrone:
                                const iso = createIsoLayer(nextLayerId++, layer.options);
                                iso.geoJSON = layer.geoJSON;
                                return iso;
                            case ToolType.Amenity:
                                const amenity = createAmenityLayer(nextLayerId++, layer.options);
                                amenity.geoJSON = layer.geoJSON;
                                return amenity;
                            case ToolType.CustomerData:
                                const customers = createCustomerDataLayer(nextLayerId++, layer.options);
                                customers.geoJSON = layer.geoJSON;
                                return customers;
                        }
                    }) });
                result.layers.forEach(layer => (layer.group = result));
                return result;
            });
        },
        setMapName(state, name) {
            state.map.name = name;
        },
        setMapDescription(state, description) {
            state.map.description = description;
        },
        setZoom(state, payload) {
            state.map.zoom = payload;
        },
        setCenter(state, payload) {
            state.map.center = payload;
        },
        addMarker(state, payload) {
            const marker = createMarkerLayer(nextLayerId++);
            if (payload.draggedMarker) {
                marker.options.position = payload.customPosition;
            }
            else {
                marker.options.position = state.map.center.clone();
            }
            const group = {
                groupId: nextGroupId++,
                type: LayerGroupType.Marker,
                layers: [marker],
            };
            marker.group = group;
            state.layerGroups.push(group);
        },
        addCircle(state) {
            const marker = createMarkerLayer(nextLayerId++);
            marker.options.position = state.map.center.clone();
            const circle = createCircleLayer(nextLayerId++);
            circle.options.position = state.map.center.clone();
            const group = {
                groupId: nextGroupId++,
                type: LayerGroupType.Circle,
                layers: [marker, circle],
            };
            marker.group = group;
            circle.group = group;
            state.layerGroups.push(group);
        },
        addIsochrone(state) {
            const marker = createMarkerLayer(nextLayerId++);
            marker.options.position = state.map.center.clone();
            const geoLayer = createIsoLayer(nextLayerId++);
            marker.options.position = state.map.center.clone();
            const group = {
                groupId: nextGroupId++,
                type: LayerGroupType.Iso,
                layers: [marker, geoLayer],
            };
            marker.group = group;
            geoLayer.group = group;
            state.layerGroups.push(group);
        },
        addAmenity(state) {
            const ame = createAmenityLayer(nextLayerId++);
            // register group
            const group = {
                groupId: nextGroupId++,
                type: LayerGroupType.Amenities,
                layers: [ame],
            };
            ame.group = group;
            // push to layergroups
            state.layerGroups.push(group);
        },
        addCustomerData(state) {
            const data = createCustomerDataLayer(nextLayerId++);
            // register group
            const group = {
                groupId: nextGroupId++,
                type: LayerGroupType.CustomerData,
                layers: [data],
            };
            data.group = group;
            // push to layergroups
            state.layerGroups.push(group);
        },
        addArea(state) {
            /* */
            const stat = createAreasLayer(nextLayerId++);
            // // register group
            const group = {
                groupId: nextGroupId++,
                type: LayerGroupType.Areas,
                layers: [stat],
            };
            stat.group = group;
            // push to layergroups
            state.layerGroups.push(group);
        },
        setGroupVisibility(state, options) {
            state.layerGroups.find(x => x.groupId === options.groupId).layers.forEach(x => (x.isVisible = options.isVisible));
        },
        setGeoJson(state, payload) {
            if ('groupId' in payload) {
                const group = state.layerGroups.find(x => x.groupId === payload.groupId);
                if (group) {
                    group.layers[1].geoJSON = payload.geoJson;
                }
            }
            if ('layerId' in payload) {
                const layer = state.layerGroups.flatMap(x => x.layers).find(x => x.id === payload.layerId);
                if (layer) {
                    layer.geoJSON = payload.geoJson;
                }
            }
        },
        setMapOption(state, options) {
            state.map = Object.assign(Object.assign({}, state.map), options);
        },
        setLayerOptions(state, options) {
            const matches = [];
            for (const group of state.layerGroups) {
                Array.prototype.push.apply(matches, group.layers.filter(gr => gr.id === options.id));
            }
            for (const layer of matches) {
                for (const _option in options) {
                    // the backend expects numbers instead of string wrapped numbers; try to convert
                    let value = options[_option];
                    if (!Array.isArray(options[_option]) && !isNaN(parseFloat(options[_option]))) {
                        value = parseFloat(value);
                    }
                    if (layer.hasOwnProperty('options') && layer.options.hasOwnProperty(_option)) {
                        layer.options[_option] = value;
                        continue;
                    }
                    if (layer.hasOwnProperty('styling') && layer.styling.hasOwnProperty(_option)) {
                        layer.styling[_option] = value;
                        continue;
                    }
                    if (layer.hasOwnProperty(_option)) {
                        layer[_option] = value;
                    }
                }
            }
        },
        setLayerGroupOptions(state, options) {
            const group = state.layerGroups.find(x => x.groupId === options.groupId);
            if (group) {
                for (const i in options) {
                    if (group.hasOwnProperty(i)) {
                        group[i] = options[i];
                    }
                }
            }
        },
        moveLayer(state, payload) {
            const matches = [];
            for (const group of state.layerGroups) {
                Array.prototype.push.apply(matches, group.layers.filter(x => x.id === payload.id));
            }
            for (const layer of matches) {
                layer.options.position = payload.position.clone();
            }
        },
        moveLayerGroup(state, payload) {
            const group = state.layerGroups.find(x => x.groupId === payload.groupId);
            if (group) {
                for (const i in group.layers) {
                    group.layers[i].options.position = payload.position.clone();
                }
            }
        },
        // Removes a layergroup
        removeLayerGroup(state, payload) {
            const groupIndex = state.layerGroups.findIndex(x => x.groupId === payload.groupId);
            if (groupIndex > -1) {
                state.layerGroups.splice(groupIndex, 1);
            }
        },
        setMapRef(state, payload) {
            mapRef = payload.leafletObject;
            zoomService = new ZoomService(mapRef);
            new SimpleMapScreenshoter().addTo(mapRef);
        },
        setAllStatistics: (state, data) => (state.allStatistics = data),
        setMeasureFilterOptions: (state, data) => (state.measureFilterOptions = data),
    },
};
