import 'systemjs-webpack-interop/auto-public-path';

import MapsModule from './components/Maps/store';
import MapWidget from './components/Maps';

const install = app => {
    if(!Window.runtime.store) throw 'Expected Window.runtime.store to be available, has Vuex not been exposed yet?';

    if(!Window.runtime.store.hasModule('maps')) Window.runtime.store.registerModule('maps', MapsModule);
    app.component('MapWidget', MapWidget);
    app.component('MapComponent', MapWidget); // alias
}

export { install as plugin };