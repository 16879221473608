import * as L from 'leaflet/dist/leaflet-src.esm';
export default class ZoomService {
    constructor(mapRef) {
        this.defaultFlyToOptions = {
            maxZoom: 17,
            // padding: new L.Point(100, 100)
        };
        this.mapRef = mapRef;
        // this.mapRef.on('move', () => this.mapRef.fire('viewreset'))
    }
    currentZoom() {
        return this.mapRef.getZoom();
    }
    zoomToLayers(leafletIdArray, defaultFlyToOptions = null) {
        /* Flies to the bounds of all layers in the map */
        const _bounds = this.getBoundsLayers(leafletIdArray);
        const _defaultFlyToOptions = defaultFlyToOptions == null ? this.defaultFlyToOptions : defaultFlyToOptions;
        if (_bounds)
            this.mapRef.flyToBounds(_bounds, _defaultFlyToOptions);
    }
    zoomToBbox(_bounds, defaultFlyToOptions = null) {
        /* Flies to the provided bounding box */
        const _defaultFlyToOptions = defaultFlyToOptions == null ? this.defaultFlyToOptions : defaultFlyToOptions;
        if (_bounds)
            this.mapRef.flyToBounds(_bounds, _defaultFlyToOptions);
    }
    getBoundsLayers(leafletIdArray) {
        /* Returns LatLngBounds for the LeafletId's in the provided array */
        let resBounds = null;
        this.mapRef.eachLayer((layer) => {
            // Skip all layers that are not present in the leafletIdArray
            if (!leafletIdArray.includes(layer['_leaflet_id']))
                return;
            // Get and compare bounds
            resBounds = this.getOuterBounds(resBounds, this.getBoundsFromLayer(layer));
        });
        return resBounds;
    }
    getBoundsFromLayer(layer) {
        /* Returns the bounds of a layer. Checks out multiple locations where geographical information may be stores:
            - _mRadius for circles
            - _latlng for points
            - _latlngs for polygons
            - _layers for featurecollections
        */
        // Define the output
        let lbounds = null;
        // Retrieve outer bounds
        if (layer._mRadius)
            // CIRCLE
            lbounds = this.getOuterBounds(lbounds, layer.getBounds());
        if (layer._latlngs)
            // POLYGON
            lbounds = this.getOuterBounds(lbounds, layer.getBounds());
        if (layer._latlng)
            // POINT
            lbounds = this.getOuterBounds(lbounds, new L.LatLngBounds(layer.getLatLng(), layer.getLatLng()));
        if (layer._layers)
            // FEATURECOLLECTION
            Object.values(layer._layers).forEach(subLayer => {
                lbounds = this.getOuterBounds(lbounds, this.getBoundsFromLayer(subLayer));
            });
        // return
        return lbounds;
    }
    getOuterBounds(bounds1, bounds2) {
        /* Combines two LatLngBounds to returns the outermost LatLngs or null */
        // Both bounds are null:  return null
        // Either bounds is null: return non-null bound
        if (bounds1 == null || bounds2 == null)
            return bounds1 || bounds2;
        // let layer_min_lat = Math.min(bounds1.getSouth(), bounds2.getSouth());
        // let layer_min_lng = Math.min(bounds1.getWest(), bounds2.getWest());
        // let layer_max_lat = Math.max(bounds1.getNorth(), bounds2.getNorth());
        // let layer_max_lng = Math.max(bounds1.getEast(), bounds2.getEast());
        // let southWest = new L.LatLng(layer_min_lat, layer_min_lng);
        // let northEast = new L.LatLng(layer_max_lat, layer_max_lng);
        // let bounds = new L.LatLngBounds(southWest, northEast);
        const bounds = bounds1.extend(bounds2);
        return bounds;
    }
}
