<script>
import {ref, inject, h, onMounted, onUnmounted, reactive, watch, getCurrentInstance} from 'vue';
import {useStore} from 'vuex';
import {gridLayer} from 'leaflet/dist/leaflet-src.esm';
export default {
    name: 'MapLayerDataLoader',

    props: {
        layer: Object,
        divisionId: String, //Metadata/Parameters object? -> divisionId as key
    },

    setup(props, context) {
        const leafletRef = ref({});
        
        //Provided by leaflet
        const addLayer = inject('addLayer');

        const store = useStore();

        //https://webpack.js.org/blog/2020-10-10-webpack-5-release/#native-worker-support
        //Setup a background worker for retrieving/transforming our data
        //all we should have to worry about is binding the data to our tiles
        const worker = new Worker(new URL('./mapworker.js', import.meta.url));
        const tiles = reactive([]);
        const pendingTiles = []; //Only modify tiles post load, content isn't reacitve

        //Simple numeric identifier for tracking events across different contexts
        let tileId = 0;

        //Our tileremoved/zoomupdated events may not get fired in timely manner resulting in zombie tiles
        //to combat this we'll manually remove all tiles not matching our current zoom anymore on zoom/tileremoved evetns
        const clearObsoleteTiles = () => {
            const currentZoom = store.getters['maps/getZoom'];

            //Attempt to cancel dataload actions for our pending tiles - we'll reschedule them once revisited
            for (let i = pendingTiles.length - 1; i >= 0; i--) {
                if (pendingTiles[i].zoom !== currentZoom) {
                    cancelDataLoadForTile(pendingTiles[i].__tileId);
                    pendingTiles.splice(i, 1);
                }
            }

            for (let i = tiles.length - 1; i >= 0; i--) {
                if (tiles[i].zoom !== currentZoom) {
                    tiles.splice(i, 1);
                }
            }
        };

        const loadDataForTile = tileEvt => {
            //TileEvts don't come with a clear cut set of bounds, we'll need to convert our coordinates to bounds using the leaflet provided API
            const bounds = leafletRef.value._tileCoordsToBounds(tileEvt.coords);

            //Make our element easily trackable across contexts without having to rely on references
            tileEvt.tile.__tileId = ++tileId;
            tileEvt.tile.zoom = store.getters['maps/getZoom'];
            pendingTiles.push(tileEvt.tile);

            worker.postMessage({
                name: 'loaddata',
                requestId: tileEvt.tile.__tileId,
                bounds: {
                    north: bounds.getNorth(),
                    south: bounds.getSouth(),
                    west: bounds.getWest(),
                    east: bounds.getEast(),
                },
                zoom: store.getters['maps/getZoom'],
                layerType: props.layer.options.type,
                dataType: props.layer.options.customerDataType || props.layer.options.amenityType,
                divisionId: props.divisionId,
            });
        };

        const cancelDataLoadForTile = tileId => {
            worker.postMessage({
                name: 'canceldataload',
                requestId: tileId,
            });
        };

        worker.onmessage = e => {
            if (e.data.name !== 'loaddataresponse') return;

            const tile = pendingTiles.find(t => t.__tileId === e.data.requestId);
            if (!tile) return;

            //Ignore clustered tile, no data
            if (e.data.geoJSON._clusterSize === 0) return;

            //Add a new geojson layer for our given tile?
            tile.geoJSON = e.data.geoJSON;

            //Only push post load completion - only our tiles array is reactive, not our tile itself
            tiles.push(tile);

            clearObsoleteTiles();
        };

        watch(() => store.getters['maps/getZoom'], clearObsoleteTiles);

        onMounted(() => {
            leafletRef.value = gridLayer();
            leafletRef.value.on('tileload', loadDataForTile);
            leafletRef.value.on('tileunload', tileEvt => {
                const tileIndex = tiles.findIndex(t => t.__tileId === tileEvt.tile.__tileId);
                const pendingIndex = tiles.findIndex(t => t.__tileId === tileEvt.tile.__tileId);

                if (tileIndex !== -1) tiles.splice(tileIndex, 1);

                //If our tile was still pending, we'll need to notify our worker to cancel loading said data
                if (pendingIndex !== -1) {
                    pendingTiles.splice(pendingIndex, 1);
                    cancelDataLoadForTile(tileEvt.tile.__tileId);
                }
            });

            addLayer({
                leafletObject: leafletRef.value,
            });
        });

        //Clean our managed resources upon unmounting
        onUnmounted(() => worker.terminate());

        return {leafletRef, tiles};
    },

    render() {
        //Expose tiles via slot? -> tile.geoJSON = what we render
        //Placeholder element
        return h('div', this.$slots.default({tiles: this.tiles.filter(t => t.geoJSON)}));
    },
};
</script>
