// Service for scheduling method invocations by object key
// will ensure only one invocation/key is running at a time
export default class SchedulerService {
    constructor() {
        this._backlog = new window.Map();
        this._running = new Set();
    }
    // Schedule function invocation, when a method is already executing for the given key
    // the invocation will be put on a backlog to be invoked post completion of the current running method
    // each key has its own backlog consisting of exactly one single slot
    schedule(key, fn) {
        //Schedule for invocation post running completion
        if (this._running.has(key)) {
            this._backlog.set(key, fn);
            return;
        }
        //Invoke now
        this._running.add(key);
        //Done; we don't care if we failed/succeeded
        fn().finally(() => {
            //Mark as done by first deleting from running
            this._running.delete(key);
            //Attempt to invoke next when available
            const next = this._backlog.get(key);
            this._backlog.delete(key);
            //Attempt to reschedule
            if (next)
                this.schedule(key, next);
        });
    }
    reset() {
        //Clear our backlog, running invocations can't be canceled as of now, so just let them finish
        this._backlog.clear();
    }
}
