<template>
    <l-icon v-bind="iconOptions">
        <!-- 
          While vue-leaflet explicitly checks for an `html` prop, it isn't actually included in vue-leaflet's props definition 
          this leads to Vue binding an attribute named `html` to an attribute as is, instead of providing it as prop
          luckily vue-leaflet allows us to pass HTML content directly
          this allows us to make use of leaflets built-in divIcon icon renderer (HTML as icon)
        -->
        <span
            :style="{
        '--color-default': iconColor.default,
        '--color-highlight': iconColor.highlight,
      }"
            v-html="iconContent"
        />
    </l-icon>
</template>

<script>
//Setup webpack require context allowing dynamic loading of icons

import {computed} from 'vue';
import {LIcon} from '@vue-leaflet/vue-leaflet';

import {buildSvgMarkerWithIcon} from './util';

const baseColor = '#2783c6';
//https://blog.jim-nielsen.com/2021/css-relative-colors/
//can we apply hsl css color transformations to our marker on hover? that'd be pog
//Set color variable on svg -> alter fill via css rule ( hsl( from var(--mycolor) h s calc(l - 25%) ) )
//color is now 25% darker, holy poggers
function darken(hexColor) {
    //Darken by an absolute 10%
    const factor = Math.round(255 * 0.1);
    const base = 16;

    //Remove leading '#'
    if (hexColor.startsWith('#')) hexColor = hexColor.substring(1);

    //Assume 2 characters/base color for now
    const darkened = [];
    for (let idx = 0; idx < 6; idx += 2) {
        //Parse stringvalue using our given base
        const intVal = parseInt(hexColor.substring(idx, idx + 2), base);

        //Apply a slight darkening factor over our color value, prevent it from going negative
        const darkenedVal = Math.max(intVal - factor, 0);

        //Stringify it, ensure we consist of 2 chars
        darkened.push(darkenedVal.toString(base).padStart(2, '0'));
    }

    return '#' + darkened.join('');
}

export default {
    name: 'MapMarkerIcon',

    components: {
        LIcon,
    },

    props: {
        options: Object,
    },
    setup(props) {
        //Base color: #2783c6 -> replace this with css variables
        const iconContent = computed(() =>
            buildSvgMarkerWithIcon(props.options.iconPath)
                .replaceAll(`fill="${baseColor}"`, 'fill="var(--color)"')
                .replaceAll(`stroke="${baseColor}"`, 'stroke="var(--color)"')
        );
        const iconOptions = computed(() => ({
            iconSize: props.options.iconSize,
            iconAnchor: props.options.iconAnchor,
            popupAnchor: [1, -35],
            className: props.options.isHighlighted ? 'highlighted' : '',
        }));
        const iconColor = computed(() => ({
            default: props.options.fillColor || baseColor,
            highlight: darken(props.options.fillColor || baseColor),
        }));

        return {
            iconContent,
            iconOptions,
            iconColor,
        };
    },
};
</script>

<style>
.leaflet-marker-icon .maps-svg-icon {
    /* 
      Leaflets eventing mechanism requires event targets to be explicitly registered, divIcon content isn't included in this specifier by default
      thus we'll disable our pointer events, making interactions take place on our LIcon component
      see https://github.com/Leaflet/Leaflet/pull/3288 for reference
    */
    pointer-events: none;
    --color: var(--color-default);
}

.leaflet-marker-icon:hover .maps-svg-icon,
.leaflet-marker-icon.highlighted .maps-svg-icon {
    --color: var(--color-highlight);
}
</style>
